import React from 'react';
import PropTypes from 'prop-types';

import Title from '~v2/elements/title';
import { Paragraph } from 'constants/typography';

const CatalogEntryHeader = ({ title, id, link = '' }) => (
  <div className="entry-header">
    <Title as="h2">{title}</Title>
    <Paragraph>
      {link ? (
        <a alt="Visit product detail page" href={link}>
          {id}
        </a>
      ) : (
        <React.Fragment>{id}</React.Fragment>
      )}
    </Paragraph>
  </div>
);

CatalogEntryHeader.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  link: PropTypes.string,
};

export default CatalogEntryHeader;
