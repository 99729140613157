import React from 'react';
import PropTypes from 'prop-types';

import Section from '~v2/section';
import Title from '~v2/elements/title';
import Content from '~v2/blocks/content';
import { Paragraph } from 'constants/typography';
import PhotoSlider from '~v2/blocks/photo-slider/photo-slider';
import ContentSlider from '~v2/blocks/content-slider/content-slider';

import tracePatternUrl from '!url-loader!images/svg/logo-pattern-gray.svg';
import { colors } from 'constants/theme';

import {
  ContentSlideContainer,
  SlideBox,
  BuyButtonContainer,
  SlideContainer,
} from './catalogEntry.css';

import CatalogEntryHeader from './catalogEntryHeader';

const CatalogEntry = ({
  // wordpress_id,
  sku,
  name,
  permalink,
  images,
  price,
  stock_quantity,
  // short_description,
  stock_status,
  // average_rating,
  attributes,
  purchasable,
  entryConfig,
}) => {
  const {
    theme = {
      bg: colors.spanish_white,
      text: colors.dusty_gold,
    },
  } = entryConfig;

  let packaging;
  let productTraits = [];

  productTraits.push({
    options: [`Sku ${sku}`],
  });

  attributes.map(attribute => {
    // Avoid
    if (attribute.name === 'Packaging') {
      packaging = attribute;
    } else {
      productTraits.push(attribute);
    }
  });

  productTraits.push({
    options: [`Price $${price} / lb.`],
  });

  const contentSlides = [
    {
      display: (
        <SlideContainer textColor={theme.text}>
          <SlideBox textColor={theme.text}>
            <div>
              <CatalogEntryHeader title="Product" id={name} link={permalink} />
              <ul>
                {productTraits &&
                  productTraits.map((trait, index) => (
                    <li key={`content-slide-entry-${index}`}>
                      {trait.options.map((option, index) => {
                        return (
                          <span
                            key={`${option}-entry-${index}`}
                            dangerouslySetInnerHTML={{
                              __html: `${option}${
                                trait.options[index + 1] ? ',' : ''
                              }`,
                            }}
                          />
                        );
                      })}
                    </li>
                  ))}
              </ul>
            </div>
          </SlideBox>
        </SlideContainer>
      ),
    },
    {
      display: (
        <SlideContainer textColor={theme.text} className="entry-volume">
          <SlideBox textColor={theme.text}>
            <div>
              <CatalogEntryHeader
                title="Total Volume"
                id={name}
                link={permalink}
              />
              <Title as="h3" className="entry-volume">
                {stock_quantity} lbs
              </Title>
              <Paragraph marginBottom="0">
                {stock_status}
                <br />
                minimum order:
                <br />1 lbs
              </Paragraph>
            </div>
          </SlideBox>
        </SlideContainer>
      ),
    },
    {
      display: (
        <SlideContainer textColor={theme.text} className="entry-packaging">
          <SlideBox textColor={theme.text}>
            <div>
              <CatalogEntryHeader
                title="Packaging"
                id={name}
                link={permalink}
              />
              <Content>
                <div className="text-block">
                  {packaging &&
                    packaging.options.map((option, index) => {
                      return `${option}${
                        packaging.options[index + 1] ? ', ' : ''
                      }`;
                    })}
                  <br />
                  <br />
                  Larger orders will be in “super sacks.”
                </div>
              </Content>
            </div>
          </SlideBox>
        </SlideContainer>
      ),
    },
    {
      display: (
        <SlideContainer textColor={theme.text} className="entry-shipment">
          <SlideBox textColor={theme.text}>
            <div>
              <CatalogEntryHeader title="Shipment" id={name} link={permalink} />
              <Content>
                <div className="text-block">
                  All orders can be shipped & delivered across the US.
                </div>
              </Content>
            </div>
          </SlideBox>
        </SlideContainer>
      ),
    },
  ];

  return (
    <Section
      boundsProps={{
        fluid: true,
        style: { width: '100%', alignItems: 'center' },
        TABLET_DOWN: { direction: 'vertical', padding: '3rem 0' },
        TABLET: { direction: 'horizontal', padding: '3.5rem 0' },
        DESKTOP_UP: { padding: '0' },
      }}
      containerProps={{
        bgColor: theme.bg,
        fontColor: theme.text,
        style: {
          backgroundImage: `url('${tracePatternUrl}')`,
          backgroundPosition: 'center right',
          backgroundRepeat: 'repeat-y',
        },
      }}
      left={{
        children: <PhotoSlider items={images} />,
        boxProps: {
          fluid: true,
          // className: 'phone-order-plus-one',
          TABLET_DOWN: { width: '100%', shiftRight: true },
          TABLET: { width: '120%', margin: '0 -15% 0 -20%' },
          DESKTOP_UP: { width: '100%', margin: '0 -20% 0 -30%' },
        },
      }}
      right={{
        boxProps: {
          fluid: true,
          fill: true,
          TABLET_DOWN: { width: '90%', margin: '2rem auto' },
          TABLET: { width: '40%', margin: '0 3rem 0 0' },
          DESKTOP_UP: { width: '50%' },
        },
        children: (
          <div>
            <ContentSlideContainer>
              <ContentSlider items={contentSlides} />
            </ContentSlideContainer>

            <BuyButtonContainer textColor={theme.bg}>
              <a href={permalink}>
                {purchasable === false ? (
                  <Title>Sold Out!</Title>
                ) : (
                  <Title>Buy Now</Title>
                )}
              </a>
            </BuyButtonContainer>
          </div>
        ),
      }}
    />
  );
};

CatalogEntry.propTypes = {
  wordpress_id: PropTypes.number.isRequired,
  permalink: PropTypes.string.isRequired,
  sku: PropTypes.string,
  images: PropTypes.array,
  attributes: PropTypes.array,
  price: PropTypes.string,
  name: PropTypes.string,
  stock_quantity: PropTypes.number,
  short_description: PropTypes.string,
  stock_status: PropTypes.string,
  average_rating: PropTypes.string,
  purchasable: PropTypes.bool,
  entryConfig: PropTypes.shape({
    theme: PropTypes.shape({
      bg: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
};

export default CatalogEntry;
