import styled from 'styled-components';
import { palette } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  
  .slider {
    z-index: 15;
    ${'' /* padding-bottom: 55px; */}
    ${'' /* width: 88% !important; */}
${'' /* 
    ${MEDIA.PHONE`
      margin-top: -4rem;
    `}; */}

  }

  .slider-frame {
    overflow: hidden;
    z-index: 5;
  }


  .slider .image-container {
    max-width: 100%;
    ${'' /* width: 330px; */}
    height: auto;
    margin: 0 auto;
  }

  .slider .paging-item {
    .paging-dot {
      background: #ccc none repeat scroll 0% 0% !important;
    }

    &.active {
      .paging-dot {
        background: #999 none repeat scroll 0% 0% !important;
      }
    }
  }

  .slide-controls {
    background: none;
    border: none;
    cursor: pointer;
    padding: 2rem 1rem;
    outline-color: #3a3a3a;
  
    ${MEDIA.PHONE`
      display: none;
    `};  
  }

  .feature-item {
    display: none;
    ${MEDIA.PHONE`
      display: block;
      background: ${palette.offBlack};
      position: absolute;
      bottom: 0;
    `};
  }

  .slider-control-centerleft,
  .slider-control-centerright {
    opacity: 0;
    transition: opacity 250ms ease;
    z-index: 5;
    
    svg {
      height: 75px;
      fill: #fff;
    }
  }

  &:hover {
    .slider-control-centerleft,
    .slider-control-centerright {
      opacity: 0.6;

      &:hover {
        opacity: 0.95;
      }
    }
  }
`;
