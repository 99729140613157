import styled from 'styled-components';
import { colors } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const ContentSlideContainer = styled.div`
  width: 100%;
`;

export const SlideBox = styled.div`
  box-sizing: border-box;
  background-color: ${props => (props.bgColor ? props.bgColor : colors.white)};
  color: ${props =>
    props.textColor ? props.textColor : colors.collective_red};
  box-shadow: 0 4px 15px -6px rgba(0, 0, 0, 0.5);

  min-height: 400px;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;

  padding: 3rem;

  ${MEDIA.PHONE`
    min-height: 300px;
  `};

  ${MEDIA.TABLET`
    min-height: 340px;
    padding: 2.5rem;
  `};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BuyButtonContainer = styled.div`
  margin-top: 2rem;

  & > a {
    position: relative;
    box-sizing: border-box;
    display: block;
    background-color: ${props =>
      props.bgColor ? props.bgColor : colors.white};
    box-shadow: 0 4px 15px -6px rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 0;
    padding: 1rem 0;
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    z-index: 100;
    align-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;

    font-size: 3.25rem;
    text-decoration: none;

    transition: 125ms ease-in background, 125ms ease-out color;
    background-color: ${props =>
      props.bgColor ? props.bgColor : colors.dusty_gold};
    cursor: ${props => (!props.isDisabled ? 'pointer' : 'not-allowed')};
    color: ${colors.white};

    &:hover {
      color: ${props =>
        props.textColor ? props.textColor : colors.collective_red};
      background-color: ${props =>
        props.bgColor ? props.bgColor : colors.white};
    }

    h2 {
      margin-bottom: 0;
    }
  }
`;

export const SlideContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  font-family: 'Archivo Black';
  font-weight: 700;

  ${MEDIA.TABLET`
    font-weight: 400;
    font-size: 1.15rem;
  `};

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.entry-volume {
    text-align: center;

    h3 {
      margin-bottom: 0;
    }
  }

  &.entry-order {
    align-items: center;
    display: flex;
  }

  .entry-header {
    text-align: center;

    a {
      color: ${props =>
        props.textColor ? props.textColor : colors.collective_red};
      text-decoration: none;

      &:hover {
        color: ${props =>
          props.textColor ? props.textColor : colors.collective_red};
        text-decoration: underline;
      }
    }
  }

  .text-block {
    max-width: 290px;
    margin: 0 auto;
  }

  h1,
  h2 {
    margin-bottom: 0rem;
    letter-spacing: 0px;
  }

  h2 {
    line-height: 3.25rem;

    ${MEDIA.TABLET`
      line-height: 2.5rem;
    `};
  }

  ul {
    list-style: none;

    li {
      position: relative;
      text-transform: uppercase;
      margin-bottom: 0.2rem;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '- ';
        display: block;
        position: absolute;
        top: 0;
        left: -8px;
      }
    }
  }
`;
