import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image';

import LeftArrowSVG from 'images/svg/left-chevron.svg';
import RightArrowSVG from 'images/svg/right-chevron.svg';

import { Container } from './photo-slider.css';

const PhotoSlider = ({ items }) => {
  const [slideIndex, setIndex] = useState(0);

  return (
    <Container className="photo-carousel-container">
      <Carousel
        slideIndex={slideIndex}
        slidesToShow={1}
        autoplay={true}
        width="100%"
        heightMode="current"
        wrapAround={true}
        transitionMode="fade"
        autoplayInterval={10000}
        afterSlide={index => setIndex(index)}
        renderCenterLeftControls={({ previousSlide }) => (
          <button className="slide-controls prev" onClick={previousSlide}>
            <LeftArrowSVG />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button className="slide-controls next" onClick={nextSlide}>
            <RightArrowSVG />
          </button>
        )}
        renderBottomCenterControls={null}
      >
        {items &&
          items.map((item, index) => {
            // Crude approach to accepting multiple object types
            let imageSharp;
            if (item.localFile) {
              imageSharp = item.localFile.childImageSharp;
            } else if (item.image) {
              imageSharp = item.localFile.image.childImageSharp;
            }

            return (
              <div className="slide-container" key={index.toString()}>
                <div className="image-container">
                  <Img fluid={imageSharp ? imageSharp.fluid : {}} />
                </div>
              </div>
            );
          })}
      </Carousel>
    </Container>
  );
};

PhotoSlider.propTypes = {
  items: PropTypes.array,
};

export default PhotoSlider;
