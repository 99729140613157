import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';

import LeftArrowSVG from 'images/svg/left-chevron.svg';
import RightArrowSVG from 'images/svg/right-chevron.svg';

import { Container } from './content-slider.css';

const ContentSlider = ({ items }) => {
  const [slideIndex, setIndex] = useState(0);

  return (
    <Container
      className="content-carousel-container"
      svgColor={items[slideIndex].text}
    >
      <Carousel
        slideIndex={slideIndex}
        slidesToShow={1}
        // autoplay={true}
        width="100%"
        wrapAround={true}
        // autoplayInterval={10000}
        heightMode="current"
        afterSlide={index => setIndex(index)}
        renderCenterLeftControls={({ previousSlide }) => (
          <button className="slide-controls prev" onClick={previousSlide}>
            <LeftArrowSVG />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button className="slide-controls next" onClick={nextSlide}>
            <RightArrowSVG />
          </button>
        )}
        renderBottomCenterControls={null}
      >
        {items &&
          items.map((item, index) => {
            return (
              <div className="slide-container" key={index.toString()}>
                <div className="content-container">{item.display}</div>
              </div>
            );
          })}
      </Carousel>
    </Container>
  );
};

ContentSlider.propTypes = {
  items: PropTypes.array,
};

export default ContentSlider;
