import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

import Layout from 'components/layout';
import Head from 'components/head';
import Section from '~v2/section';
import Content from '~v2/blocks/content';
import Media from '~v2/blocks/media';
import Copy from '~v2/elements/copy';
import Box from '~v2/elements/container/box';
import CatalogEntry from '~v2/pages/catalog/catalogEntry';

// import { colors } from 'constants/theme';
import { FlyInFromLeft } from 'constants/pose';

function getCatalogConfig(index, config = {}) {
  return config[index] ? config[index] : {};
}

const HeaderImageContainer = styled.div`
  transform: rotate(0);

  ${MEDIA.MIN_TABLET`
    transform: rotate(32deg);

  `};

  ${MEDIA.MIN_DESKTOP`
    transform: rotate(24deg);
  `};

  ${MEDIA.MIN_LG_DESKTOP`
    transform: rotate(17deg);
  `};
`;

const Index = ({
  data: {
    catalogJson: { title, header, catalog_config },
    allWcProducts: { nodes: wpProducts },
  },
}) => (
  <Layout>
    <Head
      pageTitle={title}
      pageDescription="Trace offers security and differentiation to some of the best hemp cultivators in the business. Take a look at their harvest, and secure some for yourself."
    />
    <Section
      boundsProps={{
        fluid: true,
        className: '',
        valign: 'center',
        style: {
          width: '100%',
          overflow: 'hidden',
          minHeight: '50vh',
        },
        TABLET_DOWN: { direction: 'vertical' },
        TABLET: {
          direction: 'horizontal',
          padding: '0',
          margin: '3rem auto 0',
        },
        DESKTOP_UP: {
          direction: 'horizontal',
          padding: '6rem 0 0',
          margin: '3rem auto 0',
        },
      }}
      left={{
        children: (
          <HeaderImageContainer>
            <Media fluid={header.image.childImageSharp.fluid} />
          </HeaderImageContainer>
        ),
        boxProps: {
          fluid: true,
          shiftDown: true,
          className: 'phone-order-plus-one',
          PHONE: { margin: '-30px 0 0' },
          TABLET_DOWN: { width: '100%' },
          TABLET: { width: '125%', margin: '0 -90px -120px -300px' },
          DESKTOP: { width: '85%', margin: '0 -100px -125px -290px' },
          LG_DESKTOP: { width: '70%', margin: '32px 0 -125px -13%' },
        },
      }}
      right={{
        children: (
          <Box containerMargin="0 0 0 3rem" containerWidth="420px">
            <FlyInFromLeft>
              <Content
                header={{
                  as: 'h1',
                  text: header.title,
                  innerHtml: true,
                }}
                // button={{
                //   style: 'solid',
                //   text: 'Goto Exchange Homepage',
                //   to: 'https://exchange.tracevt.com/',
                //   externalLink: true,
                // }}
              >
                <Copy>
                  <div dangerouslySetInnerHTML={{ __html: header.body }} />
                </Copy>
              </Content>
            </FlyInFromLeft>
          </Box>
        ),
        boxProps: {
          fluid: true,
          fill: true,
          TABLET_DOWN: { width: '100%' },
          TABLET: { width: '60%', padding: '0', margin: '40px -0% 0 0' },
          DESKTOP: { margin: '0' },
          DESKTOP_UP: { width: '50%' },
        },
      }}
    />

    {wpProducts.map((entry, index) => (
      <div key={`catalog-entry-${index.toString()}`}>
        <CatalogEntry
          entryIndex={index}
          entryConfig={getCatalogConfig(index, catalog_config)}
          {...entry}
        />
      </div>
    ))}
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query CatalogQuery {
    catalogJson {
      title
      header {
        title
        body
        image {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      catalog_config {
        theme {
          bg
          text
        }
      }
    }
    allWcProducts(
      filter: { status: { eq: "publish" }, stock_status: { eq: "instock" } }
      limit: 20
    ) {
      nodes {
        wordpress_id
        sku
        name
        price
        permalink
        short_description
        average_rating
        attributes {
          name
          options
        }
        stock_quantity
        stock_status
        purchasable
        images {
          name
          localFile {
            childImageSharp {
              fluid(maxWidth: 640, quality: 80) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
